import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC-RXghUAAM6L485q1kKpTAif91DD4bQ0U",
  authDomain: "dollar-pixel.firebaseapp.com",
  projectId: "dollar-pixel",
  storageBucket: "dollar-pixel.appspot.com",
  messagingSenderId: "442154027182",
  appId: "1:442154027182:web:5c73f3bbf034cd0d8ac53f",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

export const firestore = firebaseApp.firestore();

export default firebase;
