import React, { useEffect } from "react";
import "./App.css";
import { firestore } from "./firebase";
import useState from "react-usestateref";
import { Row, Col, Container } from "react-bootstrap";
import Web3 from "web3";
import abi from "./abi.json";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";

function App() {
  const [wallet, setWalletAddress, walletRef] = useState("");
  const [submitted, setSubmitted, ref] = useState(false);

  const [web3, setWeb3] = useState("");
  const [add, setAdd] = useState("");
  const [instance, setInstance] = useState(null);
  const [mintNumber, setMintNumber] = useState(1);
  const [totalMinted, setTotalMinted] = useState(null);

  useEffect(() => {
    let web3 = new Web3(Web3.givenProvider);
    setWeb3(web3);

    const ins = new web3.eth.Contract(
      abi,
      "0xEcDe5D8966cD646139a9034F13923d2ca49F353A"
    );

    setInstance(ins);

    if (window.ethereum) {
      const getTotalMinted = async () => {
        var tokenCount = await ins.methods.totalSupply().call();
        console.log(tokenCount);
        setTotalMinted(tokenCount);
      };
      getTotalMinted();
    }
  }, []);

  async function requestAccount() {
    console.log("Requesting account...");

    // ❌ Check if Meta Mask Extension exists
    if (window.ethereum) {
      console.log("detected");

      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setAdd(accounts[0]);
      } catch (error) {
        console.log("Error connecting...");
      }
    } else {
      window.open("https://metamask.app.link/dapp/dollarpixel.art/");
      alert("Meta Mask not detected");
    }
  }

  const mint = async () => {
    if (add) {
      if (instance) {
        console.log(
          await instance.methods.mint(mintNumber).send({
            from: add,
            value: (
              (await instance.methods.cost().call()) * mintNumber
            ).toString(),
          })
        );
      } else {
        console.log("error");
      }
    } else {
      alert("Please Connect your wallet");
    }
  };

  const plus = () => {
    if (mintNumber < 20) setMintNumber(mintNumber + 1);
  };
  const minus = () => {
    if (mintNumber > 1) {
      setMintNumber(mintNumber - 1);
    }
  };

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   await firestore.collection("whitelist").add({ address: walletRef.current });
  //   setWalletAddress("");
  //   setSubmitted(true);
  // };

  // var addArray = [];

  // const getData = async () => {
  //   firestore
  //     .collection("whitelist")
  //     .get()
  //     .then((querySnapshot) => {
  //       const data = querySnapshot.docs.map((doc) => doc.data());
  //       // console.log(data);
  //       data.map((e) => {
  //         // console.log(e.address);
  //         addArray.push(e.address);
  //       });
  //       console.log(addArray.length);
  //     });
  // };

  return (
    <div className="main">
      <div className="logodiv">
        <div className="h-100 d-flex align-items-center justify-content-center">
          <img
            className="img-fluid "
            src={require("./assets/img/textlogo.png").default}
            alt=""
          />
        </div>
      </div>

      <Container className="cusbg">
        <Container className="d-flex justify-content-center">
          <div className="">
            <div className="text-center">
              <div className="">
                <div className=" text-center rmt">
                  <div onClick={requestAccount} className="btn cusbtn">
                    <span className="font-weight-bold text-uppercase">
                      {add ? add.substring(0, 15) : "Connect Wallet"}
                    </span>
                  </div>
                </div>
              </div>

              <div className="mt-5">
                <div className=" text-center rmt">
                  <div className="d-flex justify-content-center align-items-center mt-5">
                    <div className="mx-3">
                      <AiFillMinusCircle onClick={minus} size={60} />
                    </div>
                    <div className="display-number d-flex justify-content-center align-items-center">
                      <span className="number">{mintNumber}</span>
                    </div>
                    <div className="mx-3">
                      <AiFillPlusCircle onClick={plus} size={60} />
                    </div>
                  </div>
                  <div className="text-center mt-5">
                    <button onClick={mint} className="btn cusbtn">
                      <span className="font-weight-bold">Mint Now</span>
                    </button>
                  </div>
                  <div className="text-center mt-4">
                    <h5 className="font-weight-bold bfont">
                      {" "}
                      {totalMinted} / 10000
                    </h5>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-3">
              <div className="d-flex align-items-center justify-content-center">
                <div className="text-center">
                  <div className="">
                    <p className="subtitle">
                      "Dollar Pixel" is a collection of 10000 Dollar degens.{" "}
                      <br /> Join us now as we embark on a pixel-perfect journey
                      of <br />
                      creativity and collectible treasures. It's time to mint{" "}
                      <br />
                      your way to digital greatness!
                    </p>
                  </div>
                  <div className="">
                    <a
                      className="mx-2"
                      href="https://twitter.com/dollarpixelart"
                      target="_blank"
                    >
                      Twitter
                    </a>
                    <a
                      className="mx-2"
                      href="https://etherscan.io/address/0xecde5d8966cd646139a9034f13923d2ca49f353a#code"
                      target="_blank"
                    >
                      Contract
                    </a>
                    <a
                      className="mx-2"
                      href="https://opensea.io/collection/dollarpixels"
                      target="_blank"
                    >
                      Opensea
                    </a>
                    <a
                      className="mx-2"
                      href="https://t.me/dollarpixelart"
                      target="_blank"
                    >
                      Telegram
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Container>
    </div>
  );
}

export default App;
